<template>
  <div class="container mt-5">
    <div>
      <h1 class="fw-bold mb-5 text-center noto-sans-kr-extra-bold">
        다이렉트 ISO
      </h1>
      <h2 class="fw-bold text-primary noto-sans-kr-light text-center">
        ISO심사비.. 부담스러우시죠? <br />
        최대 50%까지<br class="mobile-only" />
        절감할 수 있습니다!
      </h2>
      <p class="noto-sans-kr-light text-center mt-3">
        간단한 정보만 입력하면 100% 비대면으로<br class="mobile-only" />
        <strong>즉시 견적을 확인할 수 있습니다.</strong><br />
        이제 더 이상 ISO인증 때문에 고민하지 마세요.
      </p>
    </div>
    <div>
      <h2 class="fw-bold mt-5 noto-sans-kr-extra-bold text-primary">
        기본 정보
      </h2>
      <p>견적 산출을 위한 정보입니다. 정확하게 입력해주세요</p>
      <div class="border p-3 rounded bg-light">
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="name" class="form-label fw-bold">기업명</label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              required
              v-model="name"
            />
          </div>
          <div class="col-md-6">
            <label for="email" class="form-label fw-bold">이메일</label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              required
              v-model="email"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="phone" class="form-label fw-bold"
              >주요 제품/서비스</label
            >
            <input
              type="text"
              class="form-control"
              id="product"
              name="product"
              required
              v-model="product"
              placeholder="ex) 건축공사, 페인트의 제조, 경영컨설팅 서비스 등"
            />
          </div>
          <div class="col-md-6">
            <label for="name" class="form-label fw-bold">종업원 수 </label>
            <input
              type="number"
              class="form-control"
              id="employee"
              name="employee"
              min="0"
              step="1"
              required
              v-model="employee"
            />
          </div>
        </div>
        <!-- <hr
          style="width: 80%; margin-bottom: 30px; margin-top: 30px"
          class="mx-auto"
        /> -->

        <div class="row mb-3">
          <div class="col-md-6"></div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-6">
            <label for="name" class="form-label fw-bold">인증표준 </label>
            <br />
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="qms"
                value="qms"
                name="standards"
                v-model="standards"
              />
              <label class="form-check-label noto-sans-kr-light" for="qms"
                >ISO 9001</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="ems"
                value="ems"
                name="standards"
                v-model="standards"
              />
              <label class="form-check-label noto-sans-kr-light" for="ems"
                >ISO14001</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="ohsms"
                value="ohsms"
                name="standards"
                v-model="standards"
              />
              <label class="form-check-label noto-sans-kr-light" for="ohsms"
                >ISO45001</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="cms"
                value="cms"
                name="standards"
                v-model="standards"
              />
              <label class="form-check-label noto-sans-kr-light" for="cms"
                >ISO22716</label
              >
            </div>
          </div>
          <div class="col-md-6">
            <label for="auditType" class="form-label fw-bold">심사유형</label
            ><br />
            <!-- 제목과 첫 체크박스 사이에 줄바꿈 추가 -->
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="auditType"
                id="initial"
                value="initial"
                required
                v-model="auditType"
              />
              <label class="form-check-label noto-sans-kr-light" for="initial"
                >최초심사</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="auditType"
                id="surveillance"
                value="surveillance"
                v-model="auditType"
              />
              <label
                class="form-check-label noto-sans-kr-light"
                for="surveillance"
                >사후심사</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="auditType"
                id="renewal"
                value="renewal"
                v-model="auditType"
              />
              <label class="form-check-label noto-sans-kr-light" for="renewal"
                >갱신심사</label
              >
            </div>
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-12">
            <label for="auditType" class="form-label fw-bold">지역</label><br />
            <!-- 제목과 첫 체크박스 사이에 줄바꿈 추가 -->
            <div class="row">
              <!-- 서울/경기 -->
              <div class="col-6 col-md-4 col-lg-2 form-check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="locale"
                    id="seoul"
                    value="seoul"
                    required
                    v-model="locale"
                  />
                  <label class="form-check-label noto-sans-kr-light" for="seoul"
                    >서울/경기</label
                  >
                </div>
              </div>
              <!-- 강원 -->
              <div class="col-6 col-md-4 col-lg-2 form-check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="locale"
                    id="kangwon"
                    value="kangwon"
                    v-model="locale"
                  />
                  <label
                    class="form-check-label noto-sans-kr-light"
                    for="kangwon"
                    >강원</label
                  >
                </div>
              </div>
              <!-- 충청 -->
              <div class="col-6 col-md-4 col-lg-2 form-check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="locale"
                    id="chungcheong"
                    value="chungcheong"
                    v-model="locale"
                  />
                  <label
                    class="form-check-label noto-sans-kr-light"
                    for="chungcheong"
                    >충청</label
                  >
                </div>
              </div>
              <!-- 나머지 지역 반복 -->
              <!-- 전라 -->
              <div class="col-6 col-md-4 col-lg-2 form-check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="locale"
                    id="junla"
                    value="junla"
                    v-model="locale"
                  />
                  <label class="form-check-label noto-sans-kr-light" for="junla"
                    >전라</label
                  >
                </div>
              </div>
              <!-- 경상 -->
              <div class="col-6 col-md-4 col-lg-2 form-check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="locale"
                    id="gyungsang"
                    value="gyungsang"
                    v-model="locale"
                  />
                  <label
                    class="form-check-label noto-sans-kr-light"
                    for="gyungsang"
                    >경상</label
                  >
                </div>
              </div>
              <!-- 제주 -->
              <div class="col-6 col-md-4 col-lg-2 form-check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="locale"
                    id="jeju"
                    value="jeju"
                    v-model="locale"
                  />
                  <label class="form-check-label noto-sans-kr-light" for="jeju"
                    >제주</label
                  >
                </div>
              </div>
              <!-- 해외 -->
              <!-- 제주 -->
              <div class="col-6 col-md-4 col-lg-2 form-check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="locale"
                    id="overseas"
                    value="overseas"
                    v-model="locale"
                  />
                  <label
                    class="form-check-label noto-sans-kr-light"
                    for="overseas"
                    >해외</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 class="fw-bold mt-5 noto-sans-kr-extra-bold text-primary">
        선택 정보
      </h2>
      <p>필요한 선택항목을 추가하세요. 인증획득이 더욱 원활해집니다.</p>
      <div class="border p-3 rounded bg-light">
        <div class="row mb-md-2">
          <div class="col-md-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="document"
                id="document"
                name="document"
                v-model="document"
              />
              <label class="form-check-label text-primary" for="document">
                시스템 문서 포함 &nbsp;&nbsp;<small
                  class="noto-sans-kr-light text-muted"
                >
                  (+ 50,000원) - 매뉴얼/절차서 준비 포함</small
                >
              </label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="response"
                id="response"
                name="response"
                v-model="response"
              />
              <label class="form-check-label text-primary" for="response">
                심사 대응 포함&nbsp;&nbsp;<small
                  class="noto-sans-kr-light text-muted"
                >
                  (+ 150,000원) - 심사에 참여하여 고객사의 심사를
                  지원합니다.</small
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <button class="btn btn-primary my-5 px-5" @click="getAuditFee">
          견적 계산
        </button>
      </div>
      <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-2">견적을 계산하고 있습니다. 잠시만 기다려주세요...</p>
      </div>
    </div>
    <div v-if="quote_result">
      <div class="alert alert-warning fs-5" role="alert">
        {{ quote_result.message }}
      </div>
    </div>
    <div
      class="container mb-5 border shadow p-3"
      id="responseContainer"
      v-if="quote_result"
    >
      <!-- <hr style="width: 50%; margin-bottom: 30px" /> -->
      <h2 class="fw-bold">심사비: {{ quote_result.quote }}</h2>
      <span class="text-primary"
        ><em>{{ quote_result.end_message }}</em></span
      >
      <p>견적이 만족스러우시면 인증심사를 신청하세요!</p>
      <p class="fs-5 my-3 text-primary"></p>
      <button class="btn btn-danger" @click="goToApply">인증 신청</button>
    </div>
  </div>

  <!-- iso content -->
  <div class="container">
    <h3 class="fw-bold">진행절차</h3>
    <!-- step1~step6까지 문장 처음에는 이모티콘을 적용해서 단계별로 보이도록 구성 -->
    <div class="list-group mt-3">
      <!-- STEP 1 -->
      <div class="list-group-item">
        <font-awesome-icon icon="user-check" size="2x" class="text-primary" />
        <br />
        <strong>STEP 1 인증표준선택(고객)</strong><br />
        <span class="font-bol">필요한 표준을 결정하십시오.</span>
        표준에 대해 잘 모르신다면 우리 사이트를 둘려보십시오. 원하는 경우 하나
        이상을 수행하도록 선택할 수 있습니다. 원하는 표준이 무엇인지 알게 되면<a
          href="/"
          >"견적 계산"</a
        >으로 이동하십시오. 간단한 정보만 입력하면 끝입니다. 고객이 요청하기
        전에 절대 먼저 연락하지 않습니다.
      </div>
      <!-- STEP 2 -->
      <div class="list-group-item">
        <font-awesome-icon
          :icon="['fas', 'file-word']"
          size="2x"
          class="text-primary"
        />
        <br />
        <strong>STEP 2 견적가 검토 및 결정(고객)</strong><br />
        다이렉트ISO가 제시한 견적을 검토하여 만족한 경우 인증심사신청 버튼을
        클릭하여 심사신청을 진행하십시오.
      </div>
      <!-- STEP 3 -->
      <div class="list-group-item">
        <font-awesome-icon
          icon="face-smile-wink"
          size="2x"
          class="text-primary"
        />
        <br />
        <strong>STEP 3 심사원 배정 및 심사계획서 발행（인증기관）</strong><br />
        정식으로 등록된 인증기관을 선정하고 심사원을 배정합니다. 배정된 심사원이
        고객사에 연락하여 심사신청정보와 차이가 있는지 확인하고 이상없는 경우
        심사계획서 발행을 진행합니다.
      </div>
      <!-- STEP ４ -->
      <div class="list-group-item">
        <font-awesome-icon
          icon="fa-solid fa-comment-dollar"
          size="2x"
          class="text-primary"
        />
        <br />
        <strong>STEP 3 심사비 납부(고객)</strong><br />
        인증진행을 위해 심사계획서에 기재된 납부정보에 따라 심사비를 납부하여야
        합니다. 심사비는 인증기관에 납부하며 세금계산서가 발행됩니다.
      </div>
      <!-- STEP 4 -->
      <div class="list-group-item">
        <font-awesome-icon
          :icon="['fas', 'magnifying-glass']"
          size="2x"
          class="text-primary"
        />
        <br />
        <strong>STEP 4 인증심사 진행(심사원, 고객사)</strong><br />
        배정된 심사원이 인증심사를 수행합니다. 고객사는 심사원의 변경을 요청할
        수 있습니다. 심사와 관련한 구체적인 일정 및 기타 사항은 심사팀장이
        고객사와 직접 소통합니다.
      </div>
      <!-- STEP 5 -->
      <div class="list-group-item">
        <font-awesome-icon
          :icon="['fas', 'building-user']"
          size="2x"
          class="text-primary"
        />
        <br />
        <strong>STEP 5 인증기관</strong><br />
        심사팀장은 서명된 심사보고서를 인증기관에 제출하고 인증기관은
        심사보고서를 검토하고 심사목적이 달성되고 보고서에 문제가 없으면
        인증서를 발행합니다. 고객사는 인증유효여부를 언제든지 확인할 수
        있습니다.
      </div>
      <!-- STEP 6 -->
      <div class="list-group-item">
        <font-awesome-icon
          :icon="['fas', 'screwdriver-wrench']"
          size="2x"
          class="text-primary"
        />
        <br />
        <strong>스텝 6 시스템 유지관리(고객사)</strong><br />
        인증을 유효하게 유지하기 위해서는 매년 사후심사를 수검하여야 합니다.
        사후심사를 받지 않을 경우 인증효력은 상실됩니다. 다이렉트ISO는 귀사의
        인증이 유효하게 유지되도록 알림을 제공합니다. 알림을 통해 ISO인증을
        계속해서 유지할 것인지 결정하면 됩니다. 언제나 다이렉트ISO의
        견적시스템을 활용한다면 귀사의 비용부담은 최소화 될 것임을 약속합니다.
      </div>
    </div>

    <!-- faq -->
    <div class="container my-5">
      <h3 class="fw-bold">자주 묻는 질문</h3>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              1. 개인기업도 ISO인증을 취득할 수 있나요?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              ISO는 기업의 유형에 제한이 없습니다. 개인기업 또는 소상공인도
              ISO를 취득할 수 있습니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              2. 창업한지 얼마 안된 기업도 ISO인증을 취득할 수 있나요?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              네, 가능합니다. 경영시스템 구축은 창업시점과 일치하지 않을 수
              있습니다. 따라서 창업시점은 ISO 취득에 영향을 미치지 않습니다.
              다만, 일반적으로 운영실적을 확인할 수 있는 정도를 요구할 수
              있습니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              3. ISO인증 취득하는데까지 기간이 얼마나 걸리나요?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              원칙적으로 3~4주 소요됩니다. 심사신청부터 1단계심사, 2단계심사를
              거치게 되며 일반적으로 3~4주 소요됩니다. 부적합 등의 발견사항이
              있을 경우는 시정조치 후 인증이 가능하므로 시간이 더 소요될 수
              있습니다. 물론, 인증서의 사용시점이 정해져 있는 경우는 문제 없도록
              처리가능합니다. 사후심사의 경우는 1주일 정도 소요됩니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse4"
              aria-expanded="false"
              aria-controls="collapse4"
            >
              4. 1인기업도 ISO인증을 취득할 수 있나요?
            </button>
          </h2>
          <div
            id="collapse4"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              네, 가능합니다. ISO인증심사는 종업원 수에 제한을 두고 있지
              않습니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse5"
              aria-expanded="false"
              aria-controls="collapse5"
            >
              5. 기존에 인증을 보유하고 있는데도 심사신청을 해도 되나요?
            </button>
          </h2>
          <div
            id="collapse5"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              네, 가능합니다. 인증심사는 고객의 요청에 따라 심사원 및 인증기관을
              변경할 수 있습니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse6"
              aria-expanded="false"
              aria-controls="collapse6"
            >
              6. ISO인증은 매년 받아야 하나요? 비용은 얼마나 되나요?
            </button>
          </h2>
          <div
            id="collapse6"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              네, 매년 인증심사(매1년 사후심사, 매3년 갱신심사)를 받아야 합니다.
              사후 또는 갱신심사를 받지 않으면 인증효력은 취소되며 ISO인증관련
              문양, 문구 등을 일체 사용하지 못합니다. 비용은 종업원 수에 따라
              차이가 있습니다. 5인 이하 기준으로 한개 표준에 50~60만원 정도
              소요됩니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse7"
              aria-expanded="false"
              aria-controls="collapse7"
            >
              7. ISO내부심사원 교육도 가능한가요?
            </button>
          </h2>
          <div
            id="collapse7"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              네, 가능합니다. 온라인, 오프라인 교육이 가능하며, 교육비는
              수강인원과 표준에 따라 다릅니다. 업계 최저가로 내부심사원 교육이
              가능하오니 문의 주시기 바랍니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse8"
              aria-expanded="false"
              aria-controls="collapse8"
            >
              8. ISO인증을 취득하고 나서도 계속해서 관리해주나요?
            </button>
          </h2>
          <div
            id="collapse8"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              아니오, 다이렉트ISO는 인증심사와 컨설팅을 구분하여 제공하고
              있습니다. 서류작성을 대행한다거나 시스템 유지보수는 유료 컨설팅
              서비스로 제공하고 있습니다.
              <strong
                >그러나, 횟수에 제한없이 무료 교육훈련을 계속해서 제공하고
                있습니다.</strong
              >이 서비스를 이용하는 것만으로도 충분할 것입니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse9"
              aria-expanded="false"
              aria-controls="collapse9"
            >
              9. 다이렉트ISO만의 특별한 서비스가 있나요?
            </button>
          </h2>
          <div
            id="collapse9"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              네, ISO는 지속적인 유지관리가 중요합니다. 다이렉트ISO는 무제한
              화상 트레이닝을 지원하고 있습니다. 담당인원이 자주 변경되는
              중소기업에 아주 적합한 서비스입니다. 트레이닝을 통해 스스로 시스템
              운영이 가능하도록 무제한 지원하고 있습니다.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse10"
              aria-expanded="false"
              aria-controls="collapse10"
            >
              10. ISO심사 결과 인증서를 취득하지 못하는 경우도 있나요?
            </button>
          </h2>
          <div
            id="collapse10"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              ISO는 합격과 불합격으로 구분하기보다 부족한 부분이 발견되면 그
              부분을 보완해 나가는 과정이라고 생각하시는 것이 맞을 것 같습니다.
              ISO의 기본 이념도 "지속적 개선"이므로 최종적으로 인증서를 취득하지
              못하는 경우는 없다고 보시면 되겠습니다. 다만, 보완사항이 발견되면
              보완조치를 하는 시간만큼 기간이 더 소요될 수 있습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios' // axios를 임포트합니다.
export default {
  name: 'HomeView',
  data() {
    return {
      name: '',
      email: '',
      contact: '',
      phone: '',
      product: '',
      bizType: '',
      employee: 1,
      standards: [],
      auditType: '',
      locale: '',
      document: false,
      response: false,
      quote_result: null,
      loading: false
    }
  },
  mounted() {},
  methods: {
    async getAuditFee() {
      this.loading = true // 로딩 시작

      // 필수 입력값 체크
      if (!this.name) {
        alert('기업명을 입력해주세요.')
        this.loading = false // 입력값이 부족하므로 로딩 종료
        return
      }
      if (!this.email) {
        alert('이메일을 입력해주세요.')
        this.loading = false // 입력값이 부족하므로 로딩 종료
        return
      }
      if (!this.product) {
        alert('주요 제품/서비스를 입력해주세요.')
        this.loading = false // 입력값이 부족하므로 로딩 종료
        return
      }
      if (!this.employee) {
        alert('종업원 수를 입력해주세요.')
        this.loading = false // 입력값이 부족하므로 로딩 종료
        return
      }
      if (this.standards.length === 0) {
        alert('인증표준을 선택해주세요.')
        this.loading = false // 입력값이 부족하므로 로딩 종료
        return
      }
      if (!this.auditType) {
        alert('심사유형을 선택해주세요.')
        this.loading = false // 입력값이 부족하므로 로딩 종료
        return
      }
      if (!this.locale) {
        alert('지역을 선택해주세요.')
        this.loading = false // 입력값이 부족하므로 로딩 종료
        return
      }

      // this.$nextTick(() => {
      //   this.scrollToBottom() // DOM 업데이트가 완료된 후 스크롤을 아래로 이동
      // })

      // 파라미터 데이터 생성
      const data = {
        name: this.name,
        email: this.email,
        product: this.product,
        employee: this.employee,
        standards: this.standards,
        auditType: this.auditType,
        locale: this.locale,
        document: this.document,
        response: this.response
      }

      // console.log('파라미터 데이터: ', data)

      // 요청 전 3초간 대기
      await new Promise((resolve) => setTimeout(resolve, 1000))

      // axios로 서버에 견적 요청, header, cors 문제로 인해 서버 주소를 변경

      try {
        const response = await axios.post('/api/quote', data)

        // console.log('견적 결과: ', response.data)
        // 결과를 화면에 표시
        this.quote_result = response.data
        // this.$nextTick(() => {
        //   this.scrollToBottom() // DOM 업데이트가 완료된 후 스크롤을 아래로 이동
        // })
      } catch (error) {
        console.error('견적 요청 실패: ', error)
      } finally {
        this.loading = false // 로딩 종료
      }
    },

    scrollToBottom() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    },

    goToApply() {
      this.$router.push({
        path: '/apply',
        query: { id: this.quote_result.email }
      })
    }
  }
}
</script>

<style scoped>
* {
  font-family: 'Noto Sans KR', sans-serif;
  /* font-family: 'sans-serif'; */
}
.list-group-item {
  padding-top: 20px;
  padding-bottom: 20px;
}
/* .accordion-body {
  color: gray;
} */
.accordion-button {
  background-color: #f8f9fa;
  font-weight: bold;
}
.mobile-only {
  display: none; /* 기본적으로는 보이지 않음 */
}

@media (max-width: 768px) {
  .mobile-only {
    display: block; /* 모바일 화면에서만 보임 */
  }
}
</style>
